import Link from 'next/link'
import Router from 'next/router'
import { usePathname } from 'next/navigation'
import { useEffect, useRef, useState } from 'react'
import { SetupLogo, Logo, capitalizeFirstLetter, Button, HamburgerMenu, notifySuccess } from '@bolt-chat-core/shared-ui'
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
// import { setLogout } from '../middleware/utils'
import Avatar from '@mui/material/Avatar'
import { useAuthStore } from '../../stores/auth-store'
import { useProjectStore } from '../../stores/project-store'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { FaPlus } from 'react-icons/fa'
import { motion } from 'framer-motion'
import { Team } from 'apps/chat-ui/types/authTypes'

const Navbar = (props: {
  context: string
  isSidebarOpen?: boolean
  setIsSidebarOpen?: React.Dispatch<React.SetStateAction<boolean>> | null
}) => {
  const pathname = usePathname()
  const user = useAuthStore((state) => state.user)
  const teams = useAuthStore((state) => state.teams)
  const selectedTeam = useAuthStore((state) => state.selectedTeam)
  const project = useProjectStore((state) => state.project)
  const count = useProjectStore((state) => state.metaCount)
  const [navbar, setNavbar] = useState(false)
  const [teamDropdown, setTeamDropdown] = useState(false)
  const [teamDropdownMobile, setTeamDropdownMobile] = useState(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const dropdownRefMobile = useRef<HTMLLIElement | null>(null)
  const userRole = user?.role
  const userHasAccessToManualProjects = user?.hasAccessToManualProjects
  const title = project?.title
  const projectId = project?.id
  const fullname = user?.fullname
  const projectStatus = project?.status
  const homeNavbar = [
    {
      name: 'AI Projects',
      path: 'projects',
      visible: true,
      redirect: '/projects',
    },
    {
      name: 'Meta Analysis',
      path: 'meta-analysis',
      visible: true,
      redirect: '/meta-analysis',
    },
    {
      name: 'Discover',
      path: 'discover',
      visible: true,
      redirect: '/discover',
    },
    {
      name: 'Projects',
      path: '/projects',
      visible: userRole === 'ADMIN' && userHasAccessToManualProjects ? true : false,
      redirect: '/projects',
    },
  ]

  const setupNavbar = [
    {
      name: 'Setup',
      path: `setup`,
      visible: true,
      redirect: `/setup/1/${projectId}`,
    },
    {
      name: 'Audience',
      path: `audience`,
      visible: projectStatus === 'SETUP' ? false : true,
      redirect: `${`/audience/${projectId}`}`,
    },
    {
      name: 'Live Chat',
      path: `livechat`,
      visible: false,
      redirect: `/livechat/${projectId}`,
    },
    {
      name: 'Report',
      path: `report`,
      visible: projectStatus === 'COMPLETE',
      redirect: `/report/${projectId}`,
    },
  ]

  const logout = () => {
    Router.push('/auth/login')
    useAuthStore.getState().logout()
  }

  const closeDropdown = (event: MouseEvent | any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setTeamDropdown(false)
    }
  }
  const handleSelectedTeam = (team: Team) => {
    setTeamDropdown(false)
    setTeamDropdownMobile(false)
    useAuthStore.getState().updateSelectedTeam(team)
    notifySuccess(`Successfully switched to ${team.name} team`)
    Router.push('/projects')
  }
  useEffect(() => {
    document.addEventListener('click', closeDropdown)

    return () => {
      document.removeEventListener('click', closeDropdown)
    }
  }, [])
  return (
    <nav className="min-h-[67px] max-h-[67px]">
      <div className="w-full bg-[#181818]">
        <div className="flex mx-4 lg:mx-8 justify-between min-h-[67px] gap-1">
          {/* Primary menu and logo */}
          <div className="flex items-center gap-2 my-2 justify-start w-full">
            {props.context === 'setup' && !pathname.startsWith('/audience') && !pathname.startsWith('/report') && (
              <button
                className="block lg:hidden"
                onClick={() => props.setIsSidebarOpen && props?.setIsSidebarOpen(!props?.isSidebarOpen)}
              >
                <HamburgerMenu height={20} width={null} />
              </button>
            )}
            {props.context === 'setup' ? <SetupLogo /> : <Logo height={140} width={140} />}
            {props.context === 'setup' ? (
              <>
                <Link href="/projects" className="self-center">
                  <span className="self-center text-[#939396] cursor-pointer hover:text-white ml-3 text-[15px]">Home</span>
                </Link>
                <svg
                  className="self-center ml-3 mt-[3px]"
                  width="12"
                  height="12"
                  viewBox="0 0 4 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.58492 3L0.294922 5.295L0.999922 6L3.99992 3L0.999922 0L0.294922 0.705L2.58492 3Z"
                    fill="white"
                    fillOpacity="0.87"
                  />
                </svg>
                <span className="self-center ml-3 text-[15px] mt-[3px] max-w-[100px] sm:max-w-[200px] whitespace-nowrap overflow-hidden text-ellipsis">
                  {title}
                </span>
              </>
            ) : null}
          </div>
          <div className="hidden lg:flex gap-8 justify-center w-full">
            <ul className="items-center justify-center space-y-8 sm:flex sm:space-x-6 sm:space-y-0">
              {props.context === 'home' ? (
                <>
                  {homeNavbar.map(
                    (val, index) =>
                      val.visible && (
                        <li
                          style={
                            pathname?.split('/').filter(Boolean)[0] === val.path
                              ? {
                                  borderBottom: 'solid 3px #7039BE',
                                  lineHeight: '4rem',
                                }
                              : { lineHeight: '4rem' }
                          }
                          className={`${
                            pathname?.split('/').filter(Boolean)[0] === val.path ? 'text-white' : 'text-[#939396] '
                          }hover:text-white text-[15px]`}
                          key={index}
                        >
                          <Link href={val.redirect}>
                            {val.name === 'Meta Analysis' ? (
                              <>
                                {val.name}
                                {count !== 0 && (
                                  <span className="bg-[#F44622] py-1 px-2 leading-none rounded text-white text-xs ml-2">
                                    {count}
                                  </span>
                                )}
                              </>
                            ) : (
                              val.name
                            )}
                          </Link>
                        </li>
                      ),
                  )}
                </>
              ) : props.context === 'setup' ? (
                <>
                  {setupNavbar.map(
                    (val, index) =>
                      val.visible && (
                        <li
                          key={index}
                          style={
                            pathname?.split('/').filter(Boolean)[0] === val.path
                              ? {
                                  borderBottom: 'solid 3px #7039BE',
                                  lineHeight: '4rem',
                                }
                              : { lineHeight: '4rem' }
                          }
                          className={`${
                            pathname?.split('/').filter(Boolean)[0] === val.path ? 'text-white' : 'text-[#939396] '
                          }hover:text-white text-[15px]`}
                        >
                          <Link href={val.redirect}>{val.name}</Link>
                        </li>
                      ),
                  )}
                </>
              ) : null}
            </ul>
          </div>
          {/* secondary */}
          <div className="flex gap-3 justify-end w-full">
            <div ref={dropdownRef} className="hidden lg:flex items-center gap-4 relative justify-center">
              <Button
                color="purple"
                radius="full"
                size="small"
                onClick={() => setTeamDropdown(!teamDropdown)}
                dataBolt="nav-pricing"
              >
                <span className="text-nowrap ">{selectedTeam?.name}</span>
                <span className="text-[#d9d9d9] px-[5px] ">|</span>
                <span
                  className={`text-nowrap font-semibold ${
                    selectedTeam?.subscriptionType === 'STARTER' ? 'text-[#35A7FF]' : 'text-[#1ED6BB]'
                  }`}
                >
                  Credits: {selectedTeam?.credits}
                </span>
                <span
                  onClick={(e) => {
                    e.stopPropagation()
                    setTeamDropdown(!teamDropdown)
                  }}
                  className="pl-1"
                >
                  {teamDropdown ? <FaChevronUp /> : <FaChevronDown />}
                </span>
              </Button>
              <motion.div
                initial="initial"
                animate={!teamDropdown ? 'hidden' : 'visible'}
                variants={{
                  visible: { opacity: 1, y: 0 },
                  initial: { opacity: 0, y: -75 },
                  hidden: { opacity: 0, y: -25 },
                }}
                transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.3 }}
                className={`${
                  !teamDropdown && 'hidden'
                } absolute top-16 w-full min-w-[300px] gap-2 flex flex-col rounded-lg text-left py-2 px-2 border text-sm border-[#2B2D34] bg-[#23252B] z-50`}
              >
                {teams.map((e, i) => (
                  <button
                    onClick={() => handleSelectedTeam(e)}
                    key={i}
                    className={`flex text-left items-center gap-2 w-full px-2 py-1 rounded-lg ${
                      teams.length !== i + 1 && 'border-b border-[#2B2D34]'
                    } ${selectedTeam?.id === e.id && 'bg-bolt-gray-light'} pb-2`}
                  >
                    <div className="flex flex-col w-full">
                      <span className="truncate text-balance max-w-[150px]">{e.name}</span>
                      <span
                        className={`${
                          e.subscriptionType === 'STARTER'
                            ? ' text-[#35A7FF]'
                            : e.subscriptionType === 'PLUS'
                            ? 'text-bolt-green-light'
                            : 'text-white'
                        } capitalize text-xs`}
                      >
                        {e.subscriptionType.toLowerCase()}
                      </span>
                    </div>
                    <div
                      className={`flex w-fit justify-end text-nowrap ${
                        e.subscriptionType === 'STARTER' ? 'text-[#35A7FF]' : 'text-[#1ED6BB]'
                      } font-semibold`}
                    >
                      {e.credits} Credits
                    </div>
                    <span
                      onClick={(e) => {
                        e.stopPropagation()
                        Router.push('/pricing')
                      }}
                      className="bg-[#2B2D34] rounded-full p-2 items-center justify-center z-50"
                    >
                      <FaPlus />
                    </span>
                  </button>
                ))}
              </motion.div>
            </div>
            <span className="self-center">
              <span className="w-56 text-right">
                <Menu as="div" className="relative inline-block text-left">
                  <Menu.Button
                    onClick={() => setNavbar(!navbar)}
                    className="inline-flex w-full justify-center  bg-black text-sm font-medium rounded-full"
                    data-bolt="nav-bar-menu"
                  >
                    <Avatar {...stringAvatar(fullname)} />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      className={`absolute z-50 hidden xl:block right-0 mt-2 w-56 origin-top-right divide-y divide-gray-800 bg-[#181818] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                    >
                      <Menu.Item>
                        <Button
                          onClick={() => logout()}
                          size="small"
                          variant="base"
                          color="white"
                          className="font-normal w-full"
                          dataBolt="nav-bar-menu-logout"
                        >
                          Log Out
                        </Button>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-end pr-5">
        <div
          className={`relative z-[9999] self-end bg-[#181818] overflow-hidden flex flex-col xl:hidden min-w-48 origin-top duration-700  ${
            !navbar ? 'h-0' : 'h-auto py-4'
          }`}
        >
          <div className="px-8">
            <div className="flex flex-col tracking-wider font-medium">
              <ul className="grid grid-flow-row gap-2 items-center justify-between z-50">
                {props.context === 'home'
                  ? homeNavbar.map(
                      (val, index) =>
                        val.visible && (
                          <li
                            className={`list-none lg:hidden hover:text-white text-base ${
                              pathname?.split('/').filter(Boolean)[0] === val.path
                                ? 'text-white border-b-4 border-bolt-purple'
                                : 'text-[#939396] '
                            }`}
                            key={index}
                            data-bolt={`nav-${val.path}`}
                          >
                            <Link href={val.redirect}>{val.name}</Link>
                          </li>
                        ),
                    )
                  : props.context === 'setup'
                  ? setupNavbar.map(
                      (val, index) =>
                        val.visible && (
                          <li
                            className={`list-none lg:hidden hover:text-white text-base ${
                              pathname?.split('/').filter(Boolean)[0] === val.path
                                ? 'text-white border-b-[3px] w-fit border-bolt-purple'
                                : 'text-[#939396] '
                            }`}
                            key={index}
                          >
                            <Link href={val.redirect}>{val.name}</Link>
                          </li>
                        ),
                    )
                  : null}
                <li ref={dropdownRefMobile} className="block lg:hidden">
                  <button
                    className="text-white text-sm flex items-center"
                    onClick={() => setTeamDropdownMobile(!teamDropdownMobile)}
                    data-bolt="nav-pricing"
                  >
                    <span className="text-sm">{selectedTeam?.name}</span>
                    <span className="text-[#d9d9d9] px-[5px] text-sm">|</span>
                    <span
                      className={`${
                        selectedTeam?.subscriptionType === 'STARTER' ? 'text-[#35A7FF]' : 'text-[#1ED6BB]'
                      } text-sm font-semibold`}
                    >
                      Credits: {selectedTeam?.credits}
                    </span>
                    <span className="pl-3">
                      <FaChevronDown />
                    </span>
                  </button>
                  {teamDropdownMobile &&
                    teams.map((e, i) => (
                      <motion.button
                        onClick={() => handleSelectedTeam(e)}
                        initial="initial"
                        animate={!teamDropdownMobile ? 'hidden' : 'visible'}
                        variants={{
                          visible: { opacity: 1, y: 0 },
                          initial: { opacity: 0, y: -15 },
                          hidden: { opacity: 0, y: -25 },
                        }}
                        transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.3 }}
                        className={`text-sm px-2 py-1 rounded-sm pt-1 flex flex-row items-center gap-2 w-full text-left ${
                          selectedTeam?.id === e.id && 'bg-bolt-gray-light'
                        }`}
                        key={i}
                      >
                        <div className="flex flex-col w-full">
                          <span className="truncate">{e.name}</span>
                          <span
                            className={`${
                              e.subscriptionType === 'STARTER'
                                ? ' text-[#2D4392]'
                                : e.subscriptionType === 'PLUS'
                                ? 'text-bolt-green-light'
                                : 'text-white'
                            } capitalize text-xs`}
                          >
                            {e.subscriptionType.toLowerCase()}
                          </span>
                        </div>
                        <div className="flex w-fit justify-end text-nowrap">{e.credits} Credits</div>
                        <button
                          onClick={() => Router.push('/pricing')}
                          className="bg-[#2B2D34] rounded-full p-2 items-center justify-center"
                        >
                          <FaPlus />
                        </button>
                      </motion.button>
                    ))}
                </li>
                <li className="list-none">
                  <button
                    onClick={() => logout()}
                    className="font-normal text-white text-sm bg-[#393939] px-2 py-1 rounded-md z-50"
                    data-bolt="nav-bar-menu-logout"
                  >
                    Log Out
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar

function stringAvatar(name: string | undefined) {
  return {
    sx: {
      bgcolor: '#2D2D3E',
      width: '36px',
      height: '36px',
      color: '#d9d9d9',
      fontSize: 12,
      fontWeight: '500',
    },
    children: `${name ? name?.split(' ')[0][0] : 'AA'}`,
  }
}
